import React from "react"
import { FaCode, FaSketch, FaPencilRuler, FaImages} from "react-icons/fa"
const services = [
  {
    id: 1,
    icon: <FaPencilRuler className="service-icon" />,
    title: "Desarrollo de marca",
    text: `Logo, elementos complementarios de marca, papelería, merchandising, packaging, etc.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "Diseño UX/UI",
    text: `Interfaces y experiencia de usuario para webs, apps, plataformas, etc.`,
  },
  {
    id: 3,
    icon: <FaCode className="service-icon" />,
    title: "Desarrollo web",
    text: `Páginas web corporativas responsive, app web, e-commerce, etc.`,
  },
  {
    id: 4,
    icon: <FaImages className="service-icon" />,
    title: "Contenido de redes y publicidad",
    text: `Planificación y creación de contenido gráfico para redes sociales y publicidad gráfica tradicional.`,
  },
]

export default services
