import React from "react"
import { graphql } from "gatsby"
import DogMp4 from "../assets/video/bg-home-video.mp4"
import { FaChevronDown } from "react-icons/fa"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import MainWorks from "../components/MainWorks"
import Blogs from "../components/Blogs"
import Contact from "../components/Contact"
import Seo from "../components/Seo"
import ReactFullpage from "@fullpage/react-fullpage"

const IndexPage = ({
  data: {
    allStrapiProjects: { nodes: projects },
    allStrapiBlogs: { nodes: blogs },
  },
}) => {
  return (
    <ReactFullpage
      licenseKey={"YOUR_KEY_HERE"}
      scrollingSpeed={1000}
      render={({ state, fullpageApi }) => {
        return (
          <>
            <Seo title="Inicio" />

            <header className="section hero section-center">
              <div className="title-container text-center">
                <h1>
                  <span className="text-black text-bold">Jonathan Alfonso</span>
                  <span className="h1-subtitle">
                    Diseño Gráfico, Web, UX/UI
                  </span>
                </h1>
              </div>

              <div
                className="arrow arrow-down"
                onClick={() => fullpageApi.moveSectionDown()}
              >
                <FaChevronDown />
              </div>
              <video loop muted data-autoplay className="bg-video">
                <source src={DogMp4} type="video/mp4" />
              </video>
            </header>

            <Services className="section" />

            <MainWorks
              className="section"
              projects={projects}
              title="Algunos Trabajos"
            />

            <Blogs
              className="section"
              blogs={blogs}
              title="Últimos Artículos"
              showLink
            />

            <Contact className="section" />
            
            <div
              className="arrow arrow-top"
              onClick={() => fullpageApi.moveTo(1)}
            >
              <FaChevronDown />
            </div>
          </>
        )
      }}
    />
  )
}

export const query = graphql`
  {
    allStrapiProjects(sort: { order: ASC, fields: order }, filter: { featured: { eq: true } }) {
      nodes {
        id
        description
        slug
        title
        subtitle
        order
        preview {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
    allStrapiBlogs(sort: { fields: date, order: DESC }, limit: 4) {
      nodes {
        slug
        content
        desc
        date(formatString: "DD/MM/YYYY")
        id
        title
        category {
          id
          title
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
