import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const MainWork = ({ title, subtitle, stack, preview, slug }) => {
  return (
    <article>
      {preview && (
        <BackgroundImage
          fluid={preview.childImageSharp.fluid}
          className="item-portfolio"
        >
          <Link to={`/portfolio/${slug}`}>
            <div className="block-middle">
              <h3>{title}</h3>

            </div>
            <div className="portfolio-stack">
              {stack.map(item => {
                return <span key={item.id}>{item.title}</span>
              })}
            </div>
          </Link>
        </BackgroundImage>
      )}
    </article>
  )
}

export default MainWork
