import React from "react"
import Title from "./Title"
import MainWork from "./MainWork"
import { Link } from "gatsby"
const MainWorks = ({ projects, title }) => {
  return (
    <section className="section bg-white">
      <div className="section-container">
        <Title title={title} />
        <div className="porfolio-container">
          {projects.map((project, index) => {
            return <MainWork key={project.id} index={index} {...project} />
          })}
        </div>
      </div>
    </section>
  )
}

export default MainWorks
